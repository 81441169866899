/* Power reviews */
#pr-reviewdisplay {
  .p-w-r {
    .pr-rd-footer {
      &:lang(fr-e-FR) {
        .pr-helpful-yes {
          .pr-helpful-count {
            &:before {
              content: 'Oui -';
              visibility: visible;
            }
          }
        }
        .pr-helpful-no {
          .pr-helpful-count {
            &:before {
              content: 'non -';
              visibility: visible;
            }
          }
        }
      }
    }
  }
}
