.account-section {
  &__nav {
    .sidebar-menu {
      margin-bottom: 25px;
      &__item {
        border-top: 1px solid $color-black;
        a {
          text-decoration: none;
        }
      }
    }
  }
  .account-page {
    &__section {
      float: #{$ldirection};
      &:nth-child(2n) {
        clear: none;
        padding-#{$ldirection}: 2%;
      }
      &:nth-child(2n + 1) {
        border-#{$rdirection}: solid 1px $color-black;
      }
    }
  }
}

.profile-page {
  input[type='radio'] ~ label,
  input[type='radio'] ~ .label {
    margin-#{$rdirection}: 10px;
  }
  .profile-info__item {
    &_full.title-container {
      margin-bottom: 10px;
    }
  }
  .newsletter-info {
    &__fieldset {
      width: 100%;
    }
    &__item {
      margin-top: 0;
    }
  }
  .optional-info {
    &__item {
      select {
        min-width: auto;
      }
    }
  }
}

.address-book-page {
  &__content {
    .address-book,
    .payment-info {
      .address-item,
      .payment-item {
        &__controls {
          a {
            border-bottom: none;
          }
        }
      }
    }
  }
  &__overlay {
    .payment-delete,
    .address-delete {
      &__link {
        border-bottom: none;
      }
    }
  }
}

.password-sent-page {
  .sent-info__text {
    a {
      border-bottom: none;
    }
  }
}

.profile-pic-upload {
  padding: 1em;
}

.order-details-page {
  &__content {
    .order-products {
      .cart-item.product {
        .cart-item {
          &__price {
            width: 15%;
            font-size: 1em;
          }
          &__qty {
            width: 10%;
          }
        }
      }
    }
  }
}

#pro-level {
  &-form {
    .form {
      &-item {
        @include swap_direction(margin, 5px 0 5px 0);
        &.artist {
          @include swap_direction(margin, 8px 0 12px 0);
        }
        &.terms {
          @include swap_direction(margin, 10px 0 20px 0);
        }
      }
    }
  }
}
