ul {
  &.selectBox-dropdown-menu {
    &.selectBox-attached-selectBox-dropdown-menu {
      z-index: 99999;
    }
  }
}

.past-purchases-page__content {
  section {
    &.past-purchases {
      overflow: unset;
    }
  }
}

.homepage {
  &__split {
    .content-block5 {
      &__box {
        height: 700px;
      }
    }
  }
}

.esearch-nav {
  &__suggestions-wrapper {
    .esearch-product {
      &--typeahead {
        .endeca-product {
          &__image {
            width: 65%;
          }
        }
      }
    }
    .typeahead-wrapper {
      .product-results {
        padding: 0;
      }
    }
  }
  .search-form {
    &__fields {
      margin: 0 auto 35px;
    }
  }
}
