.postal_code_container {
  .button-container {
    padding-top: 1.7em;
  }
}

.account-section {
  &__nav {
    .sidebar-menu {
      &__item {
        border-top: 0;
      }
    }
  }
  .section-head {
    &__link {
      text-decoration: none;
      float: right;
    }
  }
  .account-page {
    &__section {
      &:nth-child(2n + 1) {
        border-right: 1px solid $color-gray;
        padding-right: 2%;
        width: 50%;
        border-bottom: none;
      }
      &:nth-child(2n) {
        width: 50%;
        border-bottom: none;
      }
    }
  }
}

.orders-list__table--tracking-number {
  @include breakpoint($landscape-up) {
    width: 19%;
  }
}
