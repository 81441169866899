.checkout {
  .cart-items {
    &__item {
      &--qty {
        width: 16%;
        #cart {
          .selectbox {
            min-width: 70%;
            .selectBox-label {
              @include swap_direction(padding, 0 30px 0 15px);
            }
          }
        }
      }
    }
  }
  &__sidebar {
    .offer-code-content {
      &__sign-in-link {
        display: inline-block;
      }
      &__form {
        padding-top: 10px;
        input {
          min-width: auto;
        }
        .button:hover {
          background: $color-pink;
        }
      }
    }
    .cart-item {
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .checkout-buttons-content {
    &__container {
      float: #{$rdirection};
      @include breakpoint($large-up) {
        width: 65%;
      }
    }
  }
  .checkout-panel {
    &__content {
      .valid_marker {
        background-color: transparent;
        top: -2px;
        #{$rdirection}: 23px;
      }
      .invalid_marker {
        bottom: 15px;
        background-color: transparent;
      }
      &.manual {
        .title label {
          display: none;
        }
      }
    }
    &__header {
      a.edit.button {
        color: $color-white;
      }
    }
    &--recommended-products {
      .recommended-item {
        @include swap_direction(margin, 0 0 0 10px);
        float: #{$ldirection};
        width: 32%;
        &__button {
          padding: 10px;
        }
      }
    }
    &--shipping {
      .address-to-use {
        margin-bottom: 1em;
        .ship_group_1 {
          label {
            display: block;
          }
        }
      }
      .select-menu {
        margin-bottom: 1em;
      }
      .address-form__name-fields {
        float: #{$ldirection};
        width: 100%;
      }
      .gift-options {
        &__content {
          margin-top: 20px;
          fieldset {
            &.giftwrap {
              max-width: 100%;
              .form-item {
                &.card-message {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .form-item.state {
        display: none;
      }
    }
    &--review {
      .payment-type {
        label:first-child {
          display: none;
        }
        margin-bottom: 1em;
      }
      .adpl {
        .title {
          label {
            display: none;
          }
        }
      }
      .form-item {
        &.country-id {
          padding: 0;
        }
      }
    }
  }
  &__content {
    .checkout-panel {
      &.paypal,
      &--payment,
      &--loyalty-join {
        padding: 0;
        border-bottom: 0;
      }
    }
  }
  &--confirmation-page {
    .accepted-privacy-policy {
      .label::before,
      label::before {
        content: '\E009';
      }
      input {
        &.error ~ label,
        &.checkedEmpty ~ label {
          color: $color-red;
        }
      }
    }
    .error_messages {
      .fe_validation_error {
        color: $color-red;
      }
    }
    .nolink_order_id {
      font-weight: 500;
      color: $color-black;
      letter-spacing: 0.1em;
      font-size: 14px;
    }
  }
  &.samples-page {
    .samples-content {
      &__button-group--top {
        display: none;
      }
    }
    .product-list {
      li.product {
        list-style: none;
        width: 24.5%;
        padding: 0 10px 0;
        margin: 0 0 20px;
        display: inline-block;
        text-align: center;
        vertical-align: bottom;
        a.sample-select-button {
          width: auto;
          margin: 15px 0 0;
        }
        input[type='checkbox'] ~ label,
        input[type='checkbox'] ~ .label {
          text-align: #{$ldirection};
        }
        input.sample-select {
          float: #{$ldirection};
          margin-#{$rdirection}: 0.3em;
        }
      }
    }
  }
}

.order-confirmation {
  .account-section {
    &__content {
      width: 100%;
    }
  }
}

.full_width {
  width: 100%;
}

.order_table_title {
  text-align: #{$ldirection};
  color: #000000;
}

.order_table_value {
  text-align: #{$rdirection};
  color: #000000;
  &.price {
    font-weight: bold;
  }
}

.black_text {
  color: #000000;
}

.align_top {
  vertical-align: top;
}

.viewcart {
  &-email {
    &-text {
      @include swap_direction(padding, 15px 20px 15px 0);
      border-bottom: 1px solid #cccccc;
      vertical-align: top;
      &.image {
        padding-#{$rdirection}: 15px;
        img {
          border: 1px solid #cccccc;
          width: 82px;
          height: 82px;
        }
      }
      &.product_info {
        padding-#{$rdirection}: 25px;
        width: 240px;
        .bg_color {
          @include swap_direction(margin, 3px 7px 0 0);
          display: block;
          float: #{$ldirection};
          height: 8px;
        }
      }
      &.price {
        width: 110px;
      }
      &.quantity {
        width: 75px;
      }
      &.ttl {
        width: 98px;
      }
    }
  }
}

#wrapper {
  &.relative {
    position: relative;
  }
}
