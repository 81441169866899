.checkout {
  .find-town {
    .city-results {
      .city-selected {
        padding-bottom: 0;
        padding-top: 12px;
      }
    }
  }
  &.samples-page {
    .product-list {
      .product {
        width: 25%;
        &.deactivate {
          .button {
            background: $color-gray;
          }
        }
        .button {
          border-width: 0;
        }
      }
    }
    .samples-content {
      &__button-group--top {
        margin-bottom: 20px;
      }
      &__button-group--bottom {
        margin-top: 30px;
      }
    }
  }
  &__sidebar {
    .checkout-panel {
      &--viewcart {
        .cart-block__header__item-count {
          padding-left: 10px;
        }
      }
      &__cart-accordion--handle {
        margin-left: 5px;
      }
    }
  }
  &.checkout-single-page {
    .checkout-panel {
      &__sub-heading {
        margin-bottom: 40px;
      }
      &--sub-section {
        margin-bottom: 40px;
      }
    }
  }
  .checkout-buttons-content {
    &__container {
      .paypal-smart-button {
        display: inline-block;
        width: 33%;
        height: 50px;
        padding: 0 2px;
        margin: 1px;
        &.paypal-loading {
          position: relative;
          background: $color-white url(/media/images/global/ajax-loading.gif) no-repeat center center;
          text-align: center;
        }
      }
    }
  }
}

.form-item {
  &.giftwrap {
    width: 100%;
  }
}
